define('abinpc/components/nav-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    store: _ember['default'].inject.service(),

    actions: {
      home: function home() {
        this.get('router').transitionTo('index');
      },
      picker: function picker() {
        this.get('router').transitionTo('picker');
      },
      picks: function picks() {
        this.get('router').transitionTo('picks');
      },
      scores: function scores() {
        this.get('router').transitionTo('scores');
      },
      records: function records() {
        this.get('router').transitionTo('records');
      },
      rules: function rules() {
        this.get('router').transitionTo('rules');
      }
    }
  });
});