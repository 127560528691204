define('abinpc/models/teamset', ['exports', 'ember-data'], function (exports, _emberData) {
  /*
  Teamset is where the team names will be stored. The letter in each variable refers to the region.
  The number refers to the seed. So team B5 is the 5 seed in the second region. Check out
  models/regionset to see how the regions are numbered.
  */
  exports['default'] = _emberData['default'].Model.extend({
    season: _emberData['default'].attr('string'), // Determines which season of abinpc this data belongs to
    A1: _emberData['default'].attr('string'),
    A2: _emberData['default'].attr('string'),
    A3: _emberData['default'].attr('string'),
    A4: _emberData['default'].attr('string'),
    A5: _emberData['default'].attr('string'),
    A6: _emberData['default'].attr('string'),
    A7: _emberData['default'].attr('string'),
    A8: _emberData['default'].attr('string'),
    A9: _emberData['default'].attr('string'),
    A10: _emberData['default'].attr('string'),
    A11: _emberData['default'].attr('string'),
    A12: _emberData['default'].attr('string'),
    A13: _emberData['default'].attr('string'),
    A14: _emberData['default'].attr('string'),
    A15: _emberData['default'].attr('string'),
    A16: _emberData['default'].attr('string'),
    B1: _emberData['default'].attr('string'),
    B2: _emberData['default'].attr('string'),
    B3: _emberData['default'].attr('string'),
    B4: _emberData['default'].attr('string'),
    B5: _emberData['default'].attr('string'),
    B6: _emberData['default'].attr('string'),
    B7: _emberData['default'].attr('string'),
    B8: _emberData['default'].attr('string'),
    B9: _emberData['default'].attr('string'),
    B10: _emberData['default'].attr('string'),
    B11: _emberData['default'].attr('string'),
    B12: _emberData['default'].attr('string'),
    B13: _emberData['default'].attr('string'),
    B14: _emberData['default'].attr('string'),
    B15: _emberData['default'].attr('string'),
    B16: _emberData['default'].attr('string'),
    C1: _emberData['default'].attr('string'),
    C2: _emberData['default'].attr('string'),
    C3: _emberData['default'].attr('string'),
    C4: _emberData['default'].attr('string'),
    C5: _emberData['default'].attr('string'),
    C6: _emberData['default'].attr('string'),
    C7: _emberData['default'].attr('string'),
    C8: _emberData['default'].attr('string'),
    C9: _emberData['default'].attr('string'),
    C10: _emberData['default'].attr('string'),
    C11: _emberData['default'].attr('string'),
    C12: _emberData['default'].attr('string'),
    C13: _emberData['default'].attr('string'),
    C14: _emberData['default'].attr('string'),
    C15: _emberData['default'].attr('string'),
    C16: _emberData['default'].attr('string'),
    D1: _emberData['default'].attr('string'),
    D2: _emberData['default'].attr('string'),
    D3: _emberData['default'].attr('string'),
    D4: _emberData['default'].attr('string'),
    D5: _emberData['default'].attr('string'),
    D6: _emberData['default'].attr('string'),
    D7: _emberData['default'].attr('string'),
    D8: _emberData['default'].attr('string'),
    D9: _emberData['default'].attr('string'),
    D10: _emberData['default'].attr('string'),
    D11: _emberData['default'].attr('string'),
    D12: _emberData['default'].attr('string'),
    D13: _emberData['default'].attr('string'),
    D14: _emberData['default'].attr('string'),
    D15: _emberData['default'].attr('string'),
    D16: _emberData['default'].attr('string')
  });
});