define('abinpc/models/admin', ['exports', 'ember-data'], function (exports, _emberData) {

  /*
    Admin is not a model for admin users, but rather a single-instance model that
    stores single-case variables that are needed for running the website.
  */

  exports['default'] = _emberData['default'].Model.extend({
    hash: _emberData['default'].attr('number'), // Hashed value of the admin password
    pre: _emberData['default'].attr('boolean'), // Stands for 'pre-season' determines whether to allow new picksets
    season: _emberData['default'].attr('string')
  });
});