define('abinpc/mixins/query-filter-mixin', ['exports', 'ember'], function (exports, _ember) {

		/*
    I had to create this mixin because the tool I'm using to connect Ember to Firebase (EmberFire)
    was depracated before it was fully developed. For this reason, there are some methods in the
    ember store that don't work with the intended functionality of the store. One of these methods,
    'filter' is vital to this application. This mixin is designed to work as a filter for queries.
    It will pull every instance of the object from the database, then use paramaters passed to
    filter out unneeded data. 
  */

		exports['default'] = _ember['default'].Mixin.create({

				// param: model - string - name of the model that should be querried
				// param: attributes - array - names of the attributes that should be filtered by
				// param: values - array - required values of the filtered attributes
				// **Position in attributes array corresponds with values array**

				filterEqual: function filterEqual(model, attributes, values) {
						var r = [];
						var include = true;
						var index = 0;
						this.store.findAll(model).then(function (objects) {
								objects.forEach(function (o) {
										include = true;
										for (var i = 0; i < attributes.length; i++) {
												if (o.get(attributes[i]) !== values[i]) {
														include = false;
												}
										}
										if (include) {
												r.push(o);
										}
										index++;
										if (index === objects.get('length')) {
												return r;
										}
								});
						});
				},

				// param: model - string - name of the model that should be querried
				// param: attributes - array - names of the attributes that should be filtered by
				// param: values - array - required values of the filtered attributes
				// **Position in attributes array corresponds with values array**

				filterNotEqual: function filterNotEqual(model, attributes, values) {
						var r = [];
						var include = true;
						var index = 0;
						this.store.findAll(model).then(function (objects) {
								objects.forEach(function (o) {
										include = true;
										for (var i = 0; i < attributes.length; i++) {
												if (o.get(attributes[i]) === values[i]) {
														include = false;
												}
										}
										if (include) {
												r.push(o);
										}
										index++;
										if (index === objects.get('length')) {
												return r;
										}
								});
						});
				},

				// param: model - string - name of the model that should be querried
				// param: attributes - array - names of the attributes that should be filtered by
				// param: values - array - required values of the filtered attributes
				// **Position in attributes array corresponds with values array**

				filterGreaterThan: function filterGreaterThan(model, attributes, values) {
						var r = [];
						var include = true;
						var index = 0;
						this.store.findAll(model).then(function (objects) {
								objects.forEach(function (o) {
										include = true;
										for (var i = 0; i < attributes.length; i++) {
												if (o.get(attributes[i]) <= values[i]) {
														include = false;
												}
										}
										if (include) {
												r.push(o);
										}
										index++;
										if (index === objects.get('length')) {
												return r;
										}
								});
						});
				},

				// param: model - string - name of the model that should be querried
				// param: attributes - array - names of the attributes that should be filtered by
				// param: values - array - required values of the filtered attributes
				// **Position in attributes array corresponds with values array**

				filterLessThan: function filterLessThan(model, attributes, values) {
						var r = [];
						var include = true;
						var index = 0;
						this.store.findAll(model).then(function (objects) {
								objects.forEach(function (o) {
										include = true;
										for (var i = 0; i < attributes.length; i++) {
												if (o.get(attributes[i]) >= values[i]) {
														include = false;
												}
										}
										if (include) {
												r.push(o);
										}
										index++;
										if (index === objects.get('length')) {
												return r;
										}
								});
						});
				},

				// param: model - string - name of the model that should be querried
				// param: attributes - array - names of the attributes that should be filtered by
				// param: values - array - required values of the filtered attributes
				// **Position in attributes array corresponds with values array**

				filterGreaterThanOrEqualTo: function filterGreaterThanOrEqualTo(model, attributes, values) {
						var r = [];
						var include = true;
						var index = 0;
						this.store.findAll(model).then(function (objects) {
								objects.forEach(function (o) {
										include = true;
										for (var i = 0; i < attributes.length; i++) {
												if (o.get(attributes[i]) < values[i]) {
														include = false;
												}
										}
										if (include) {
												r.push(o);
										}
										index++;
										if (index === objects.get('length')) {
												return r;
										}
								});
						});
				},

				// param: model - string - name of the model that should be querried
				// param: attributes - array - names of the attributes that should be filtered by
				// param: values - array - required values of the filtered attributes
				// **Position in attributes array corresponds with values array**

				filterLessThanOrEqualTo: function filterLessThanOrEqualTo(model, attributes, values) {
						var r = [];
						var include = true;
						var index = 0;
						this.store.findAll(model).then(function (objects) {
								objects.forEach(function (o) {
										include = true;
										for (var i = 0; i < attributes.length; i++) {
												if (o.get(attributes[i]) > values[i]) {
														include = false;
												}
										}
										if (include) {
												r.push(o);
										}
										index++;
										if (index === objects.get('length')) {
												return r;
										}
								});
						});
				},

				// param: model - string - name of the model that should be querried
				// param: attributes - array - names of the attributes that should be filtered by
				// param: operators - array - comparison to be run (in quotes): possible: '==','===',
				//	 '!=', '!==', '>', '>=' '<', '<='
				// param: values - array - required values of the filtered attributes
				// **Position in attributes array corresponds with values array**

				filterCustom: function filterCustom(model, attributes, operators, values) {
						var r = [];
						var include = true;
						var index = 0;
						this.store.findAll(model).then(function (objects) {
								objects.forEach(function (o) {
										include = true;
										for (var i = 0; i < attributes.length; i++) {
												if (operators[i] === '==' || operators[i] === '===') {
														if (o.get(attributes[i]) !== values[i]) {
																include = false;
														}
												} else if (operators[i] === '!=' || operators[i] === '!==') {
														if (o.get(attributes[i]) === values[i]) {
																include = false;
														}
												} else if (operators[i] === '>') {
														if (o.get(attributes[i]) <= values[i]) {
																include = false;
														}
												} else if (operators[i] === '>=') {
														if (o.get(attributes[i]) < values[i]) {
																include = false;
														}
												} else if (operators[i] === '<') {
														if (o.get(attributes[i]) >= values[i]) {
																include = false;
														}
												} else if (operators[i] === '<=') {
														if (o.get(attributes[i]) > values[i]) {
																include = false;
														}
												} else {
														return 'Error: operator not recognized';
												}
										}
										if (include) {
												r.push(o);
										}
										index++;
										if (index === objects.get('length')) {
												return r;
										}
								});
						});
				}
		});
});