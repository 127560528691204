define('abinpc/models/regionset', ['exports', 'ember-data'], function (exports, _emberData) {
  /*
    Regionset is where the names of the regions are stored. A is the name of the region on the 
    top left of the bracket. B is the bottom left, C is the top right, and D is the bottom
    right.
  */
  exports['default'] = _emberData['default'].Model.extend({
    season: _emberData['default'].attr('string'), // Determines which season of abinpc this data belongs to
    A: _emberData['default'].attr('string'),
    B: _emberData['default'].attr('string'),
    C: _emberData['default'].attr('string'),
    D: _emberData['default'].attr('string')
  });
});