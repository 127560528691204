define('abinpc/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      rules: function rules() {
        this.transitionTo('rules');
      },
      picker: function picker() {
        this.transitionTo('picker');
      },
      about: function about() {
        window.location.href = "http://tomheatwole.atwebpages.com";
      }
    }
  });
});