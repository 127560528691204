define('abinpc/models/player', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    season: _emberData['default'].attr('string'), // Determines which season of abinpc this data belongs to
    name: _emberData['default'].attr('string'),
    accessKey: _emberData['default'].attr('string'),

    // picks:
    pick1: _emberData['default'].attr('string'),
    pick2: _emberData['default'].attr('string'),
    pick3: _emberData['default'].attr('string'),
    pick4: _emberData['default'].attr('string'),
    pick5: _emberData['default'].attr('string'),
    pick6: _emberData['default'].attr('string'),
    pick7: _emberData['default'].attr('string'),
    pick8: _emberData['default'].attr('string'),
    pick9: _emberData['default'].attr('string'),
    pick10: _emberData['default'].attr('string'),
    pick11: _emberData['default'].attr('string'),
    pick12: _emberData['default'].attr('string'),
    pick13: _emberData['default'].attr('string'),
    pick14: _emberData['default'].attr('string'),
    pick15: _emberData['default'].attr('string'),
    pick16: _emberData['default'].attr('string'),
    pick17: _emberData['default'].attr('string'),
    pick18: _emberData['default'].attr('string'),
    pick19: _emberData['default'].attr('string'),
    pick20: _emberData['default'].attr('string'),
    pick21: _emberData['default'].attr('string'),
    pick22: _emberData['default'].attr('string'),
    pick23: _emberData['default'].attr('string'),
    pick24: _emberData['default'].attr('string'),
    pick25: _emberData['default'].attr('string'),
    pick26: _emberData['default'].attr('string'),
    pick27: _emberData['default'].attr('string'),
    pick28: _emberData['default'].attr('string'),
    pick29: _emberData['default'].attr('string'),
    pick30: _emberData['default'].attr('string'),
    pick31: _emberData['default'].attr('string'),
    pick32: _emberData['default'].attr('string'),
    pick33: _emberData['default'].attr('string'),
    pick34: _emberData['default'].attr('string'),
    pick35: _emberData['default'].attr('string'),
    pick36: _emberData['default'].attr('string'),
    pick37: _emberData['default'].attr('string'),
    pick38: _emberData['default'].attr('string'),
    pick39: _emberData['default'].attr('string'),
    pick40: _emberData['default'].attr('string'),
    pick41: _emberData['default'].attr('string'),
    pick42: _emberData['default'].attr('string'),
    pick43: _emberData['default'].attr('string'),
    pick44: _emberData['default'].attr('string'),
    pick45: _emberData['default'].attr('string'),
    pick46: _emberData['default'].attr('string'),
    pick47: _emberData['default'].attr('string'),
    pick48: _emberData['default'].attr('string'),
    pick49: _emberData['default'].attr('string'),
    pick50: _emberData['default'].attr('string'),
    pick51: _emberData['default'].attr('string'),
    pick52: _emberData['default'].attr('string'),
    pick53: _emberData['default'].attr('string'),
    pick54: _emberData['default'].attr('string'),
    pick55: _emberData['default'].attr('string'),
    pick56: _emberData['default'].attr('string'),
    pick57: _emberData['default'].attr('string'),
    pick58: _emberData['default'].attr('string'),
    pick59: _emberData['default'].attr('string'),
    pick60: _emberData['default'].attr('string'),
    pick61: _emberData['default'].attr('string'),
    pick62: _emberData['default'].attr('string'),
    pick63: _emberData['default'].attr('string'),

    // Goodies
    // 1-9 as listed in the 'rules' page without Goodie #5.
    pickG1: _emberData['default'].attr('string'),
    pickG2: _emberData['default'].attr('string'),
    pickG3: _emberData['default'].attr('string'),
    pickG4: _emberData['default'].attr('string'),
    pickG5: _emberData['default'].attr('string'),
    pickG6: _emberData['default'].attr('string'),
    pickG7: _emberData['default'].attr('string'),
    pickG8: _emberData['default'].attr('string'),
    pickG9: _emberData['default'].attr('string'),

    //Status of Goodie Picks
    sG1: _emberData['default'].attr('string'),
    sG2: _emberData['default'].attr('string'),
    sG3: _emberData['default'].attr('string'),
    sG4: _emberData['default'].attr('string'),
    sG5: _emberData['default'].attr('string'),
    sG6: _emberData['default'].attr('string'),
    sG7: _emberData['default'].attr('string'),
    sG8: _emberData['default'].attr('string'),
    sG9: _emberData['default'].attr('string'),

    // Total score from goodies
    goodieScore: _emberData['default'].attr('number')
  });
});