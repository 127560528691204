define("abinpc/templates/rules", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 138,
            "column": 0
          }
        },
        "moduleName": "abinpc/templates/rules.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-fluid");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "navOffset");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        dom.setAttribute(el2, "style", "text-align: center");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-8 col-sm-offset-2 paragraph");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        var el5 = dom.createTextNode(" Rules");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" \n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        dom.setAttribute(el4, "style", "height: 5vmin");
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-sm-10 col-sm-offset-1");
        var el6 = dom.createTextNode("\n	");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("\n	  Abinpc's scoring system works a little differently (better) than ESPN's scoring system. \n	  The traditional scoring system used by ESPN and other services is simple: Games in each\n	  round are worth twice as many points as games in the previous round. If you're good at\n	  math, then you already know that with this scoring system, choosing the correct national\n	  champion is worth 32 times as many points as picking a first round game correctly. \n	  Obviously getting the champion right is more important, but is it 32 times more important? \n	  Probably not. Doubling the points every round turns your March Madness pool into a contest\n	  of who can choose the correct national champion, and lets each previous round serve as a\n	  tie-breaker. This takes the fun out of picking the early round games. ESPN's scoring system \n	  also fails to award points for correctly picking upsets. This makes it really hard to come \n	  up with a better strategy than picking all lower seeds (or using your favorite ratings website \n	  to make every decision). Lastly, and most importantly, ESPN and other websites don't have goodies!\n	  Here is how Abinpc is scored differently:\n	");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n	");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "row");
        var el7 = dom.createTextNode("\n	  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "col-sm-10 col-sm-offset-1");
        var el8 = dom.createTextNode("\n	    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8, "style", "text-indent: 0px");
        var el9 = dom.createTextNode("\n	      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("1. Fibbonacci Scoring:");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n	      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("\n		Instead of doubling points each round, Abinpc increases each round based on the Fibbonacci\n		sequence. Games in the first round are worth 10 points, games in the second round are worth 20\n		points, third round is worth 30, fourth round is worth 50, final four games are worth 80, and\n		choosing the correct national champion is worth 130.\n	      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n	    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n	    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8, "style", "text-indent: 0px");
        var el9 = dom.createTextNode("\n	      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("2. Upset Points:");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n	      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("\n		Upset points are awarded for every correct pick that has a higher seed advancing. The number of\n		upset points is determined by (seed differential) * (upset multiplier). The seed differential is\n		simply the seed of the winning team minus the seed that would win if only lower seeds advanced. \n		Note that if a 13 seed beats a 12 seed in round 2, the seed differential is 9, not 1. Since a 4\n		seed would advance if all lower seeds advanced, choosing a 13 seed to go to the sweet 16 is still\n		worth the full upest value. The upset multipliers for each round are as follows:\n	      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n	    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n	    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row");
        dom.setAttribute(el8, "style", "height: 2vmin");
        var el9 = dom.createTextNode("\n	    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n	    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row");
        var el9 = dom.createTextNode("\n	      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("center");
        var el10 = dom.createTextNode("\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("table");
        dom.setAttribute(el10, "border", "4");
        dom.setAttribute(el10, "cellpadding", "3");
        dom.setAttribute(el10, "cellspacing", "3");
        var el11 = dom.createTextNode("\n		  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        dom.setAttribute(el11, "class", "trHead");
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode("Round #");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode("Points");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        var el13 = dom.createTextNode("Upset multiplier");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		  ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n		  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdName");
        var el13 = dom.createTextNode("1");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("10");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("1");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		  ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n		  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdName");
        var el13 = dom.createTextNode("2");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("20");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("3");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		  ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n		  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdName");
        var el13 = dom.createTextNode("3");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("30");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("5");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		  ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n		  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdName");
        var el13 = dom.createTextNode("4");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("50");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("10");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		  ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n		  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdName");
        var el13 = dom.createTextNode("Final 4");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("80");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("15");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		  ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n		  ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("tr");
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdName");
        var el13 = dom.createTextNode("Finals");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("130");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		    ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("td");
        dom.setAttribute(el12, "class", "tdBold");
        var el13 = dom.createTextNode("20");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n		  ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n		");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n	      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n	    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n	    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row");
        dom.setAttribute(el8, "style", "height:3vmin");
        var el9 = dom.createTextNode("\n	    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n	    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        dom.setAttribute(el8, "style", "text-indent: 0px");
        var el9 = dom.createTextNode("\n	      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("3. Goodies:");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n	      ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("p");
        var el10 = dom.createTextNode("\n		\"Goodies\" are what makes Abinpc unique. There are nine goodies worth varying amounts of points.\n		The first five goodies are determined by how you filled out your bracket. The remaining four are\n		submitted along with your bracket in the final screen of the 'Make Picks' page.\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(1) Lowest seeded team correctly picked to win in the first round [30 points]\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(2) Lowest seeded team correctly picked to make it to the sweet 16 [36 points]\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(3) Lowest seeded team correctly picked to make it to the final four [42 points]\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(4) Best region bracket -- Most correct picks in a single region [30 points]	 \n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(5) 16 seed bonus -- [250 points] for each correctly picked game won by a 16 seed. \n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(6) NIT champion [60 points]\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(7) Biggest first round blowout (margin of victory) [18 points]\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("(8) First conference out -- From a list of Big 10, Big 12, SEC, ACC, and Big East you will\n		select which conference will be the first to be completely eliminated. You are awarded \n		[4 * # of teams from that conference] points.\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" (9) Dark horse national champion - Your second chance to pick the national champion. Your \n		dark horse pick is completely safe if the team has a seed number of 3 or more. However, if your\n		dark horse is seeded #1 or #2 in its region and another Abinpc contestant has picked them in his\n		or her official bracket, you lose your dark horse pick immediately. Everyone who has the correct\n		dark horse pick will ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("b");
        var el11 = dom.createTextNode("split up");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" the points that team would have earned in a normal bracket \n		[130 + upset points].\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n		");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("b");
        var el11 = dom.createTextNode("Stroke rule:");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" If nobody correctly picks a goodie (#1-3,6-8), contestants who picked the\n		next best choice will ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("b");
        var el11 = dom.createTextNode("split up");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" the points for that goodie. For example, if nobody picks the NIT\n		champ, we will reward the entrant who picked the team that lasted the longest. If multiple contestants\n		had the same team or picked different teams who tied for the next longest run, they will have\n		to split the points. If they each had correctly picked the national champion, they would each\n		receive the full 60 points. This does not apply for dark horse or first conference out.\n	      ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n	    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n	  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "navOffset");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "outlet", ["loc", [null, [1, 0], [1, 10]]], 0, 0, 0, 0], ["content", "nav-bar", ["loc", [null, [4, 0], [4, 11]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});