define('abinpc/models/game', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    //team1 and team2 will be referred to by their code, not their team name. ex. A1, C14, etc
    season: _emberData['default'].attr('string'), // Determines which season of abinpc this data belongs to
    team1: _emberData['default'].attr('string'),
    team2: _emberData['default'].attr('string'),
    /*
    gameNumber starts at 1 and counts up as you read down the bracket. Start at the top left corner
    where team A1 plays team A16, then go down. Then read down the other side of the brakcet. For example, 
    game #17 is C1 against C16. The repeat for each round. Round 2 starts with game #33.. etc.
    */
    gameNumber: _emberData['default'].attr('number'),
    //Winner will take the team code. ex. A2, D9, ect...
    winner: _emberData['default'].attr('string')
  });
});