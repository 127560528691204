define('abinpc/router', ['exports', 'ember', 'abinpc/config/environment'], function (exports, _ember, _abinpcConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _abinpcConfigEnvironment['default'].locationType,
    rootURL: _abinpcConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('scores');
    this.route('picker');
    this.route('picks');
    this.route('records');
    this.route('rules');
    this.route('admin');
  });

  exports['default'] = Router;
});